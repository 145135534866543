import { useEffect, useState } from "react";
import Logo from "../components/Logo"
import { addBoostMetadata } from "../util/api";

const BOOST_CHANGE = 100;
const REFERRAL_CHANGE = 300;

const messagingPresets = [
    {
        status: "Unlocked",
        current: "1 month free",
        next: `Your next invite will bump you ${REFERRAL_CHANGE} more spots and unlock 3 months free for you and your friend.`,
        nextShort1: "3 months",
        nextShort2: "For You and a Colleague"
    },
    {
        status: "1 Invite",
        current: "3 months free",
        next: `Invite [numberOfFriends] more friend[plural] to bump ${REFERRAL_CHANGE} more spots each and unlock 6 months free. Each friend will get 3 months free, too.`,
        nextShort1: "6 months",
        nextShort2: "And 3 Months Free for a Colleague"
    },
    {
        status: "3 Invites",
        current: "6 months free",
        next: "Keep inviting to bump 100 each and get into the top 500 and get 1 year free! Each friend will get 3 months free, too.",
        nextShort1: "1 year",
        nextShort2: "And 3 Months Free for a Colleague"
    },
    {
        status: "Top 500",
        current: "1 year free",
        next: "Stay in the top 500 to keep this perk! Each friend will get 3 months free, too.",
        nextShort1: "bumped 100 spots",
        nextShort2: "And Get 3 Months Free for a Colleague"
    }
]

const emailTemplatePresets = {
    "department": {
        subject: "Check out this new AI math app called Goblins",
        body: "Hey, I just signed up for Goblins, an AI-powered math learning app that could be great for differentiating our instruction and catching up/speeding up our students. Use mysignup code, and we'll both get 3 months free! Check it out here: [link]"
    },
    "workWife": {
        subject: "Check out this new AI math app called Goblins",
        body: "Hey hey, I just signed up for Goblins, an AI-powered math learning app that looks really interesting. It could be great for differentiating our instruction and catching up/speeding up our students. Use my signup code, and we'll both get 3 months free! Check it out here: [link]"
    },
    "otherSchools": {
        subject: "Hey, I just joined Goblins",
        body: "Hey hey, I just signed up for Goblins, an AI-powered math learning app that looks really interesting. It could be great for differentiating instruction and catching up/speeding up your students. Use my signup code, and we'll both get 3 months free! Check it out here: [link]"
    }
}

const makeMailtoURL = (template, link) => {
    const emailTemplate = emailTemplatePresets[template];
    return `mailto:?subject=${emailTemplate.subject}&body=${emailTemplate.body.replace('[link]', link)}`;
}

const getMilestoneStatus = (waitlistPosition, referralPoints) => {
    // Explains the function
    /*
        0: 1 month free
        1: 3 months free
        2: 6 months free
        3: 1 year free
    */

    if (waitlistPosition < 500) { // always default to this, if so
        return 3
    }

    if (referralPoints == 0) {
        return 0
    } else if (referralPoints > 0 && referralPoints < 2) {
        return 1
    }

    return 2;
}

const AnimatedNumber = ({ value }) => {
    const [displayedValue, setDisplayedValue] = useState(value);
    const isActive = displayedValue !== value;

    useEffect(() => {
        const start = displayedValue;
        const end = value;
        if (start == end) return;

        const duration = 2000; // duration in milliseconds
        const stepTime = 50; // time between steps in milliseconds
        const steps = Math.ceil(duration / stepTime);
        const stepSize = (end - start) / steps;

        let currentStep = 0;

        const interval = setInterval(() => {
            currentStep += 1;
            setDisplayedValue(prev => {
                const nextValue = start + currentStep * stepSize;
                if (currentStep >= steps) {
                    clearInterval(interval);
                    return end;
                }
                return Math.floor(nextValue);
            });
        }, stepTime);

        return () => clearInterval(interval);
    }, [value]);

    return <div className={`text-xl ${isActive ? 'active' : ''}`}>#{displayedValue.toLocaleString()}</div>;
};

const boostsStages = [
    {
        question: "Which class(es) will you teach this year?",
        short: "grades",
        field: "boostGrades"
    },
    {
        question: "What's your name?",
        short: "name",
        field: "boostName"
    },
    {
        question: "What's your title?",
        short: "title",
        field: "boostTitle"
    },
    {
        question: "What's the name of your school?",
        short: "school",
        field: "boostSchoolName"
    },
    {
        question: "How many math students do you teach in total?",
        short: "Students",
        field: "boostStudents"
    },
]

const getLastCompletedBoostStage = (user) => {
    let lastCompletedStage = 0;
    boostsStages.forEach((stage, index) => {
        if (user[stage.field]) {
            lastCompletedStage = index + 1;
        }
    });
    return lastCompletedStage;
}

export default ({user}) => {
    const [copied, setCopied] = useState(false);
    const [waitlistPosition, setWaitlistPosition] = useState(user.position);
    const [boostInputValue, setBoostInputValue] = useState('');
    const userWasReferred = user['referredBy'] !== "none";
    const referralPoints = parseInt(user['amountReferred']) + (userWasReferred ? 1 : 0);
    const milestoneStatus = getMilestoneStatus(waitlistPosition, referralPoints);
    const messaging = messagingPresets[milestoneStatus];
    const referralLink = 'https://goblinsapp.com/?r=' + user['uid'];
    const cutReferralLink = referralLink.split('://')[1];
    const [lastCompletedBoostStage, setLastCompletedBoostStage] = useState(getLastCompletedBoostStage(user));
    // const lastCompletedBoostStage = getLastCompletedBoostStage(user);

    const handleBoostSubmit = async (user, stage, value) => {
        await addBoostMetadata(user.uid, stage.field, value);
        setBoostInputValue('');
        setLastCompletedBoostStage(lastCompletedBoostStage => lastCompletedBoostStage + 1);
    }

    useEffect(() => {
        if (lastCompletedBoostStage > getLastCompletedBoostStage(user)) {
            const newPosition = waitlistPosition - BOOST_CHANGE;
            // can't be less than 1
            setWaitlistPosition(newPosition > 0 ? newPosition : 1);
        }
    }, [lastCompletedBoostStage, user]);


    const handleCopy = (referralLink) => {
        navigator.clipboard.writeText(referralLink);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    }

    return <div class="split">
        <div className="sidebar">
            <Logo />
            <div className="position">
                <div>
                    <h1>Your Position</h1>
                    <AnimatedNumber value={waitlistPosition} />
                    {/* <span className="text-xl">#{waitlistPosition}</span> */}
                </div>
            </div>
            <div className="milestones">
                <div>
                    <div>You signed up.</div>
                    <div></div>
                </div>
                {userWasReferred && <div>
                    <div>You were referred.</div>
                    <div></div>
                </div>}
                
                {boostsStages.map((stage, index) => {
                    if (index < lastCompletedBoostStage) {
                        return <div>
                            <div>Added {stage.short}.</div>
                            <div>+100</div>
                        </div>
                    }
                })}

                {[...Array(user['amountReferred']).keys()].map((index) => {
                    return <div>
                        <div>You referred a friend.</div>
                        <div>+{REFERRAL_CHANGE}</div>
                    </div>
                })}
            </div>
        </div>
        <div className="main">
            <section>
                <div class="badges gutter" style={{
                    "backgroundImage": `url(badges/badges-${milestoneStatus + 1}-unlocked.png)`
                }}></div>
                <div>
                    <p>Congrats! You unlocked {messaging.current}!</p>
                    <h1>Now let’s get you {messaging.nextShort1}.</h1>
                    <div className="upper">
                        <div style={{"max-width": "500px"}}>{messaging.next.replace('[numberOfFriends]', 3 - referralPoints).replace('[plural]', 3 - referralPoints > 1 ? 's' : '')}</div>
                        {/* <div style={{"max-width": "500px"}}>Invite 1 colleague with the link below to bump up 500 more spots and unlock 3 months free.</div> */}
                    </div>
                    <br />
                    <div class="section-split">
                        <div class="left">
                            <div className="link" onClick={() => handleCopy(referralLink)}>
                                {cutReferralLink}
                                <div class={`copy-icon ${copied ? "active" : "" }`}></div>
                            </div>
                        </div>
                        <div class="right">
                            <span>Plus, Advance</span><br/>
                            300<br/>
                            <span>Per Sign Up</span>
                        </div>
                    </div>

                    <br />
                    <br />
                    <div className="share-buttons">
                        <p>Try these pre-made email templates!</p>
                        <br />
                        <div>
                            <a className="button" target="_blank" href={makeMailtoURL('department', referralLink)}>Your Department Colleagues</a>
                            <a className="button" target="_blank" href={makeMailtoURL('workWife', referralLink)}>Your Work Wife</a>
                            <a className="button" target="_blank" href={makeMailtoURL('otherSchools', referralLink)}>Colleagues at Other Schools</a>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </section>


            <hr />

            <section id="boosts">
                <div class="badges gutter" style={{
                    "backgroundImage": `url(figure-boost.png)`
                }}></div>
                <div>
                    <h1>Want Goblins earlier?</h1>
                    <p>Here’s a few basic questions that will bump you lower...</p>
                    <br />
                    {lastCompletedBoostStage < boostsStages.length && <div class="section-split">
                        <div class="left">
                            <form>
                                <input value={boostInputValue} onChange={(e) => setBoostInputValue(e.target.value)} style={{width: "100%"}} type="text" placeholder={boostsStages[lastCompletedBoostStage].question} />
                                <button className="primary" disabled={boostInputValue == ''} onClick={(e) => {
                                    e.preventDefault();
                                    handleBoostSubmit(user, boostsStages[lastCompletedBoostStage], boostInputValue)}
                                }>Boost Me</button>
                            </form>
                        </div>
                        <div class="right">
                            <span>Advance</span><br/>
                            100<br/>
                            <span>Positions</span>
                        </div>
                    </div>}
                    {lastCompletedBoostStage == boostsStages.length && <div>
                        <p>You've been fully boosted!</p>
                    </div>}
                </div>
            </section>
        </div>
    </div>
        

{/* 
        <div className="header">
            <Logo />
            <div className="position">
                Your Position
                <h1>#{waitlistPosition}</h1>
            </div>
        </div>
        <div className="centered-flex">

            <h2>🎉 Congrats! <br className="mobile-only" />You unlocked {messaging.current}!</h2>
            <p>
                Next up, invite friends with the link below. <br className="desktop-only" />
                {messaging.next.replace('[numberOfFriends]', 3 - referralPoints).replace('[plural]', 3 - referralPoints > 1 ? 's' : '')}
            </p>

            <div className="big-button">
                <a onClick={onClickBigButtonHandler} target="_blank" href={makeMailtoURL('otherSchools', referralLink)}>Anyone</a>
                <div>
                    <span className="line-1">{messaging.nextShort1}</span>
                    <span className="line-2">{messaging.nextShort2}</span>
                </div>
                <div className="congrats"></div>
            </div>

            <br />
            Your share code:
            <div className="link" onClick={() => handleCopy(referralLink)}>
                {cutReferralLink}
                <span>{copied ? "Copied!" : "Copy"}</span>
            </div>

            <section className="milestones">
                {[0, 1, 2, 3].map((i) => {
                    const isLast = i === 3;
                    const isLastActivated = milestoneStatus === i;
                    const isPast = milestoneStatus > i;
                    const isPastOrCurrent = milestoneStatus >= i;
                    const status = userWasReferred ? messagingPresets[i - 1]?.status : messagingPresets[i]?.status;

                    return <>
                        <div className={`item item-${i + 1} ${isPastOrCurrent ? "active" : ""}`}>
                            <figure
                                style={
                                    {"backgroundImage":
                                    `url(badges/${i + 1}${!isPastOrCurrent ? "-disabled" : ""}.png)`}
                                }>
                            </figure>
                            {isPastOrCurrent ? "Unlocked" : status}
                        </div>
                        {!isLast && <div className={`bar ${isPast ? 'full' :  isLastActivated && 'half-bar'}`}></div>}
                </>})}
            </section>
            <section className="share-buttons">
                <div>
                    <a className="button" target="_blank" href={makeMailtoURL('department', referralLink)}>Your Department Colleagues</a>
                    <a className="button" target="_blank" href={makeMailtoURL('workWife', referralLink)}>Your Work Wife</a>
                    <a className="button" target="_blank" href={makeMailtoURL('otherSchools', referralLink)}>Colleagues at Other Schools</a>
                </div>
                <p>Try these pre-made email templates!</p>
            </section>

        </div> */}
    {/* </> */}
}