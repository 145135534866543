import { useEffect, useState } from "react"
import Logo from "../components/Logo"
import { addPersonalEmail, getWaitlistLength } from "../util/api"

export default ({user}) => {
    const [waitlistLength, setWaitlistLength] = useState(null)
    const [showNotSeeingNote, setShowNotSeeingNote] = useState(false);
    const [personalEmail, setPersonalEmail] = useState('')
    const [showPlanBNote, setShowPlanBNote] = useState(false)
    const [hideSendButton, setHideSendButton] = useState(false)

    // useEffect(() => {
    //     const callAsync = async () => {
    //         setWaitlistLength(await getWaitlistLength() + 1);
    //     }
    //     callAsync();
    // }, [])

    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const submitPersonalEmail = async () => {
        if (!validateEmail(personalEmail)) {
            alert('Please enter a valid email address');
            return
        }
        await addPersonalEmail(user.uid, user.email, personalEmail);
        setHideSendButton(true);
    }

    return <>
        <div className="centered-flex">
            <Logo />
            <br />
            <div className="position">
                Position You're Reserving
                <h1>{user ? `#${user.position}` : '...'}</h1>
                {/* <h1>{waitlistLength ? `#${waitlistLength}` : '...'}</h1> */}
                {/* <h1>#2,996</h1> */}
            </div>

            <figure className="email-notice">(And you’re about to get your first month free!)</figure>

            <figure className="email-figure"></figure>
            <br />
            <h2>Welcome! Let’s verify your email.</h2>
            {/* <div className="emailBox">📩 {user?.email}</div> */}
            <br />
            <p>We've sent an email to {user?.email}. <br />Click the link to finish claiming your spot.</p>
            <br />
            <div className="emailUndeliveredButton" onClick={() => setShowNotSeeingNote(!showNotSeeingNote)}>
                Email Didn't Arrive?
                {/* <p>Not seeing the email? {showNotSeeingNote ? <>&uarr;</> : <>&darr;</>}</p> */}
                
            </div>
            <div className={`notSeeingNote ${showNotSeeingNote && !showPlanBNote ? 'active' : ''}`}>
                <h3>Email didn’t arrive?</h3>
                <p>Check your junk folder (and please let them know we’re not junk)!</p>
                <div onClick={() => setShowPlanBNote(true)} className="emailUndeliveredButton">I Didn’t Find It In Spam</div>
            </div>
            <div className={`notSeeingNote ${showPlanBNote ? 'active' : ''}`}>
                <h3>Plan B!</h3>
                <p>Some districts have strict firewalls.<br />
                Let’s try your personal email (e.g. gmail, hotmail) instead.</p>
                <br />
                
                <input type="email" placeholder="Your personal email" value={personalEmail} onChange={
                    (e) => setPersonalEmail(e.target.value)
                } />
                {hideSendButton ? <>Sent!</> : <button className="button primary" onClick={submitPersonalEmail}>Send</button>}
            </div>
        </div>
    </>
}