import './App.css';
import Onboard from './pages/Onboard';
import EmailConfirm from './pages/EmailConfirm';
import { useEffect, useState } from 'react';
import { confirmEmail, deleteUser, getUser, signUp } from './util/api';
import Main from './pages/Main';
import Modal from './components/Modal';

function App() {
  // const email = "foo@example.com";
  const [user, setUser] = useState(null);
  const [hasError, setHasError] = useState(false);

  // get id from params
  const params = new URLSearchParams(window.location.search);
  const email = params.get('email');
  const uuid = params.get('waiter_uuid');
  const refId = params.get('r');
  const emailConfirmId = params.get('confirm');
  const idToUse = email || uuid;

  // const hasFinishedMetadataInput = localStorage.getItem("metadataInput");

  const handleUserError = () => {
    // window.location.href = `https://goblinsapp.com/`
    setHasError(true);
  }
  
  useEffect(() => {
      const confirmEmailCheck = async (emailConfirmId) => {
        if (emailConfirmId) {
          await confirmEmail(emailConfirmId);
          // refresh page
          const url = new URL(window.location);
          url.searchParams.delete('confirm');
          window.history.replaceState(null, '', url.toString());
          console.log('Email confirmed and URL parameter removed!');
          // refresh
          window.location.reload();
          // window.location.href = `https://waitlist.goblinsapp.com/?email=${email}`
        }
      }

      // if (refId) {
      //   console.log('redirecting to goblins')
      //   window.location.href = `https://goblinsapp.com/?r=${refId}`
      //   return false;
      // }

      const fetch = async () => {
        console.log(email)
        if (idToUse) {
          try {
            const user = await getUser(idToUse)
            console.log(user)
            if (user === 'error') {
            // if (user.error_code) { // getwaitlist
              handleUserError();
              return false;
            }
            setUser(user);
          } catch (e) {
            console.log("Error getting user", e)
            handleUserError();
          }
        } else {
          console.log("No email provided, should redirect")
          handleUserError();
        }
      }
      confirmEmailCheck(emailConfirmId);
      fetch();
  }, [idToUse, refId]);

  return (
    <div className="App">
      {user && <>
        {!user.verified &&  <>
          <Modal><EmailConfirm user={user}></EmailConfirm></Modal>
        </>}
        <Main user={user} />
      </>}
      {!user && <>
        {!hasError && <Modal>Loading...</Modal>}
        {hasError && <Modal>
          <h1>Oops!</h1>
          <p>We weren't able to find anyone with that email. Please check the link and try again.</p>
          </Modal>}
      </>}
    </div>
  );
}

export default App;
